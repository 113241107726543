
import { defineComponent, PropType } from 'vue';

import { IncomingMessage } from '@/models/IncomingMessage';
import { highlightEmailsAndUrls } from '@/components/chat/utils';

export default defineComponent({
  props: {
    message: {
      type: Object as PropType<IncomingMessage>,
      required: true
    },
    translator: String
  },
  computed: {
    messageContentInTargetLanguage() {
      return highlightEmailsAndUrls(
        this.message.translatedContent === '' ? this.message.content : this.message.translatedContent
      );
    },
    recommendationsInTargetLanguage() {
      return this.message.translatedRecommendations.length
        ? this.message.translatedRecommendations
        : this.message.recommendations;
    },
    isTranslatedByGoogle() {
      return this.translator === 'google' && this.message.translatedContent !== '';
    }
  },
  methods: {
    handleRecommendationClick(parentMessage: IncomingMessage, recommendation: string) {
      this.$emit('newOutRecommendationMessage', parentMessage, recommendation);
    }
  }
});
