export default {
  "systemEvents": {
    "languageChange": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The language was changed to: ", _interpolate(_named("language"))])},
    "switchedToUCaaS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You were placed with a staff member."])},
    "switchToUCaaSFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee placement has failed."])},
    "sentFile": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["File \"", _interpolate(_named("file")), "\" sent."])},
    "sendFileError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Error sending the file \"", _interpolate(_named("file")), "\"."])},
    "fileSizeExceeded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The selected file \"", _interpolate(_named("file")), "\" is too large."])},
    "receivedFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File received:"])},
    "translationCapReached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The translation function is currently not available."])}
  },
  "inputContainer": {
    "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Message ..."])},
    "addEmoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Emoji"])},
    "sendFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send File"])}
  },
  "consentPage": {
    "consentingSentence": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["By using the chat, you confirm that you have read and accept our ", _interpolate(_list(0)), "."])},
    "startChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start chat"])}
  },
  "header": {
    "linkSection": {
      "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privacy policy"])},
      "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["imprint"])}
    }
  },
  "error": {
    "initError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred when starting the chat."])},
    "connectionError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A connection error has occurred. An attempt is being made to re-establish the connection."])},
    "tryAgainButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again"])},
    "initErrorHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["connection error"])},
    "connectionErrorHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["connection failed"])}
  }
}