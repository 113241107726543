
import { defineComponent } from 'vue';

import LoadingSpinner from './main/LoadingSpinner.vue';
import EncircledIcon from '@/components/EncircledIcon.vue';

export default defineComponent({
  components: { EncircledIcon, LoadingSpinner },
  props: {
    isInitError: Boolean,
    isConnectionError: Boolean
  },
  methods: {},
  computed: {}
});
