import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "system-message-wrapper my-3"
}
const _hoisted_2 = { class: "me-2 text" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { key: 3 }
const _hoisted_7 = { key: 4 }
const _hoisted_8 = { key: 5 }
const _hoisted_9 = { key: 6 }
const _hoisted_10 = {
  key: 7,
  class: "file-received"
}
const _hoisted_11 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LanguageFlag = _resolveComponent("LanguageFlag")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_FileAttachmentIcon = _resolveComponent("FileAttachmentIcon")!

  return (_ctx.message.systemEvent)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.message.content), 1),
        (_ctx.message.systemEvent === _ctx.SystemEvent.LANGUAGE_CHANGED)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_LanguageFlag, {
                "is-in-language-picker": false,
                "language-code": _ctx.message.systemEventContent.targetLocale || _ctx.$i18n.locale
              }, null, 8, ["language-code"])
            ]))
          : (_ctx.message.systemEvent === _ctx.SystemEvent.SWITCHED_TO_UCAAS)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-user" })
              ]))
            : (_ctx.message.systemEvent === _ctx.SystemEvent.SWITCH_TO_UCAAS_FAILED)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_font_awesome_icon, {
                    class: "text-warning",
                    icon: "fa-solid fa-triangle-exclamation"
                  })
                ]))
              : (_ctx.message.systemEvent === _ctx.SystemEvent.FILE_SENT)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createVNode(_component_FileAttachmentIcon, {
                      fileType: _ctx.message.systemEventContent.file.type
                    }, null, 8, ["fileType"])
                  ]))
                : (_ctx.message.systemEvent === _ctx.SystemEvent.FILE_SEND_ERROR)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createVNode(_component_font_awesome_icon, {
                        class: "text-warning",
                        icon: "fa-solid fa-triangle-exclamation"
                      })
                    ]))
                  : (_ctx.message.systemEvent === _ctx.SystemEvent.TRANSLATION_API_CAP_REACHED)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createVNode(_component_font_awesome_icon, {
                          class: "text-warning",
                          icon: "fa-solid fa-triangle-exclamation"
                        })
                      ]))
                    : (_ctx.message.systemEvent === _ctx.SystemEvent.FILE_SIZE_EXCEEDED)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                          _createVNode(_component_font_awesome_icon, {
                            class: "text-warning",
                            icon: "fa-solid fa-triangle-exclamation"
                          })
                        ]))
                      : _createCommentVNode("", true),
        (_ctx.message.systemEvent === _ctx.SystemEvent.MESSAGE_ATTACHMENT)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createVNode(_component_FileAttachmentIcon, {
                class: "file-received-icon",
                fileType: _ctx.message.systemEventContent.mimeType
              }, null, 8, ["fileType"]),
              _createElementVNode("a", {
                href: _ctx.message.systemEventContent.publicDownloadLink
              }, _toDisplayString(_ctx.message.systemEventContent.fileName), 9, _hoisted_11)
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}