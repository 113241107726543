import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-100 h-100" }
const _hoisted_2 = {
  key: 0,
  class: "container-md app-container"
}
const _hoisted_3 = {
  key: 1,
  class: "container-md app-container"
}
const _hoisted_4 = {
  key: 0,
  class: "app-container"
}
const _hoisted_5 = {
  key: 1,
  class: "app-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorComponent = _resolveComponent("ErrorComponent")!
  const _component_HeaderContainer = _resolveComponent("HeaderContainer")!
  const _component_ConsentComponent = _resolveComponent("ConsentComponent")!
  const _component_ChatContainer = _resolveComponent("ChatContainer")!
  const _component_InputContainer = _resolveComponent("InputContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isInitError || _ctx.isConnectionError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_ErrorComponent, {
            isInitError: _ctx.isInitError,
            isConnectionError: _ctx.isConnectionError
          }, null, 8, ["isInitError", "isConnectionError"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_HeaderContainer, {
            privacyPolicyUrl: _ctx.privacyPolicyUrl,
            imprintUrl: _ctx.imprintUrl,
            onChangeLanguage: _ctx.onChangeLanguage,
            languageChangeable: _ctx.languageChangeable
          }, null, 8, ["privacyPolicyUrl", "imprintUrl", "onChangeLanguage", "languageChangeable"]),
          (!_ctx.hasGivenConsentToPrivacyPolicy)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_ConsentComponent, {
                  pageTitle: _ctx.corporateIdentity.pageTitle,
                  privacyPolicyUrl: _ctx.privacyPolicyUrl,
                  onConsentedToPrivacyPolicy: _ctx.onConsent
                }, null, 8, ["pageTitle", "privacyPolicyUrl", "onConsentedToPrivacyPolicy"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_ChatContainer, {
                  chatInitDone: _ctx.chatInitDone,
                  messageList: _ctx.messageList,
                  translator: _ctx.translator,
                  class: _normalizeClass({ 'shadow-effect': _ctx.shadowEffect }),
                  onClick: _ctx.onClickChatContainer,
                  onNewOutMessage: _ctx.onNewOutMessage,
                  onNewOutRecommendationMessage: _ctx.onNewOutRecommendationMessage
                }, null, 8, ["chatInitDone", "messageList", "translator", "class", "onClick", "onNewOutMessage", "onNewOutRecommendationMessage"]),
                _createVNode(_component_InputContainer, {
                  isInUCaaSMode: _ctx.isInUCaaSMode,
                  inputIsDisabled: _ctx.inputDisabled,
                  fileIsSending: _ctx.fileIsSending,
                  showInputActions: _ctx.showInputActions,
                  showEmojiPicker: _ctx.showEmojiPicker,
                  lang: _ctx.lang,
                  onNewFileUpload: _ctx.onNewFileUpload,
                  onNewOutMessage: _ctx.onNewOutMessage,
                  onShowEmojiPicker: _ctx.onShowEmojiPicker,
                  onHideEmojiPicker: _ctx.onHideEmojiPicker,
                  onShowInputActions: _ctx.onShowInputActions,
                  onHideInputActions: _ctx.onHideInputActions,
                  onShowShadow: _ctx.onShowShadow,
                  onHideShadow: _ctx.onHideShadow
                }, null, 8, ["isInUCaaSMode", "inputIsDisabled", "fileIsSending", "showInputActions", "showEmojiPicker", "lang", "onNewFileUpload", "onNewOutMessage", "onShowEmojiPicker", "onHideEmojiPicker", "onShowInputActions", "onHideInputActions", "onShowShadow", "onHideShadow"])
              ]))
        ]))
  ]))
}