<template>
  <ul class="input-actions" v-if="showInputActions">
    <li>
      <input
        id="file-upload-input"
        type="file"
        ref="inputFile"
        accept="application/pdf, image/png, image/jpeg"
        @change="onFileChanged($event)"
        @click="onFileChanged($event)"
      />
      <button
        class="add-button btn button-outline-primary"
        onclick="document.querySelector('#file-upload-input').click()"
      >
        <font-awesome-icon class="add-icon" icon="fa-solid fa-paperclip" />
        {{ $t('inputContainer.sendFile') }}
      </button>
    </li>
    <li class="add-item">
      <button class="add-button btn button-outline-primary" @click="toggleEmojiPicker($event)">
        <font-awesome-icon class="add-icon" icon="fa-solid fa-face-laugh" />
        {{ $t('inputContainer.addEmoji') }}
      </button>
    </li>
  </ul>
  <SeparatorComponent></SeparatorComponent>
  <div class="container p-0">
    <div class="input-group-wrapper background-light-gray" :disabled="inputIsDisabled">
      <div v-show="isInUCaaSMode" class="file-group me-1 background-light-gray">
        <div class="mb-1 ms-1 selected-file" v-if="fileToSend && fileToSend.type">
          <font-awesome-icon icon="fa-solid fa-window-close" class="remove-file-icon" @click="removeFileSelection" />
          {{ fileToSend.name }}
        </div>
        <div v-show="fileIsSending" class="progress mt-0 mb-1 mx-1">
          <div
            class="primary-color-progress-bar progress-bar progress-bar-striped progress-bar-animated"
            role="progressbar"
          ></div>
        </div>
      </div>
      <div class="input-group rounded-bottom background-light display-flex justify-content-center align-items-center">
        <div v-show="isInUCaaSMode">
          <button class="btn" @click="onClickInputActionsButton">
            <font-awesome-icon icon="fa-solid fa-plus" />
          </button>
        </div>
        <input
          type="text"
          class="form-control message-input background-light border-0 shadow-none m-2"
          v-model="inputMessage"
          :placeholder="$t('inputContainer.input')"
          aria-label="type something"
          aria-describedby="button-send"
          :disabled="inputIsDisabled"
          @click="hideEmojiPicker"
          @keyup.enter="onNewOutMessage($event)"
        />
        <button
          class="btn primary-color-button btn-lg rounded-circle m-2"
          type="button"
          id="button-send"
          :disabled="inputIsDisabled"
          @click="onNewOutMessage($event)"
        >
          <font-awesome-icon icon="fa-solid fa-paper-plane" />
        </button>
        <EmojiPickerComponent v-if="showEmojiPicker" @clickEmojiPicker="onClickEmojiPicker" :lang="lang" />
      </div>
    </div>
  </div>
</template>

<script>
import SeparatorComponent from '@/components/chat/system/SeparatorComponent.vue';
import EmojiPickerComponent from '@/components/main/EmojiPickerComopnent.vue';
import insertTextAtCursor from 'insert-text-at-cursor';

export default {
  components: { EmojiPickerComponent, SeparatorComponent },
  emits: [
    'newFileUpload',
    'newOutMessage',
    'showEmojiPicker',
    'hideEmojiPicker',
    'showInputActions',
    'hideInputActions',
    'showShadow',
    'hideShadow'
  ],
  data: function () {
    return {
      inputMessage: '',
      fileToSend: null,
      fileUploadInput: HTMLInputElement,
      messageInput: HTMLInputElement
    };
  },
  mounted() {
    this.messageInput = document.querySelector('.message-input');
  },
  props: {
    isInUCaaSMode: Boolean,
    inputIsDisabled: Boolean,
    fileIsSending: Boolean,
    showInputActions: Boolean,
    showEmojiPicker: Boolean,
    lang: String
  },
  methods: {
    hideEmojiPicker() {
      this.$emit('hideEmojiPicker');
    },
    onFileChanged($event) {
      const target = $event.target;
      if (target && target.files) this.fileToSend = target.files[0];
      this.$emit('hideInputActions');
      this.$emit('hideShadow');
    },
    resetFilePicker() {
      this.$refs.inputFile = null;
      this.fileToSend = null;
    },
    removeFileSelection() {
      this.resetFilePicker();
    },
    toggleEmojiPicker() {
      if (this.showEmojiPicker) {
        this.$emit('hideEmojiPicker');
      } else {
        this.$emit('showEmojiPicker');
      }
    },
    onClickInputActionsButton() {
      if (this.showInputActions) {
        this.hideAllInputActionsAndComponents();
      } else {
        this.$emit('showInputActions');
        this.$emit('showShadow');
      }
    },
    onClickEmojiPicker(onEmojiClickEvent) {
      insertTextAtCursor(this.messageInput, onEmojiClickEvent.unicode);
    },
    hideAllInputActionsAndComponents() {
      this.$emit('hideInputActions');
      this.$emit('hideEmojiPicker');
      this.$emit('hideShadow');
    },
    onNewOutMessage() {
      if (this.inputMessage.length > 0 && this.inputMessage.trim().length > 0) {
        this.hideAllInputActionsAndComponents();
        this.$emit('newOutMessage', this.inputMessage);
        this.inputMessage = '';
      }
      if (this.fileToSend) {
        this.$emit('newFileUpload', this.fileToSend);
        this.resetFilePicker();
      }
    }
  }
};
</script>

<style>
#file-upload-input {
  display: none;
}

.selected-file {
  padding: 0.25em 0.5em;
  width: fit-content;
  background-color: white;
  border-radius: 0.375rem;
}

.remove-file-icon {
  cursor: pointer;
}

.input-actions {
  width: 100%;
  display: flex;
  float: left;
  flex-direction: column;
  margin: 0;
  list-style-type: none;
  position: relative;
  padding-left: 0;
  z-index: 900;
}

.add-item {
  width: 100%;
}

.add-button {
  width: 100%;
  text-align: left !important;
  border-radius: 0 !important;
}

.add-icon {
  margin-right: 10px;
}

ul li:first-child button {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}
</style>
