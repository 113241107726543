
import { defineComponent } from 'vue';
import LanguageFlag from '@/components/header/LanguageFlag.vue';
import { fallbackLanguage } from '@/services/LanguageService';

export default defineComponent({
  props: ['languageChangeable'],
  components: { LanguageFlag },
  methods: {
    fallbackLanguage() {
      return fallbackLanguage;
    },
    onChangeLanguage(lang: string) {
      this.$emit('changeLanguage', lang);
    }
  }
});
