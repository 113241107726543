import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "message-wrapper-in mt-2 ms-2" }
const _hoisted_2 = { class: "incoming-message p-2" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "d-flex justify-content-start flex-column flex-wrap me-2 mb-1" }
const _hoisted_5 = { class: "d-flex flex-row flex-wrap align-items-end" }
const _hoisted_6 = { class: "me-2 mt-1 info-text" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 0,
  class: "info-text",
  style: {"opacity":"0.7"}
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        innerHTML: _ctx.messageContentInTargetLanguage,
        class: "p-2 text"
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.message.time.format('HH:mm:ss')), 1),
        (_ctx.message.translatedContent !== _ctx.message.content)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              (_ctx.isTranslatedByGoogle)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, "translated by Google"))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      ((_ctx.message.recommendations || _ctx.message.translatedRecommendations) && !_ctx.message.recommendationClicked)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recommendationsInTargetLanguage, (recommendation) => {
              return (_openBlock(), _createElementBlock("button", {
                key: recommendation,
                class: "recommendation-button rounded-start rounded-top p-1 m-1",
                onClick: ($event: any) => (_ctx.handleRecommendationClick(_ctx.message, recommendation))
              }, _toDisplayString(recommendation), 9, _hoisted_10))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}