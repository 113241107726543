export default {
  "systemEvents": {
    "languageChange": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dil şu şekilde değiştirildi: ", _interpolate(_named("language"))])},
    "switchedToUCaaS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir personelin yanına yerleştirildiniz."])},
    "switchToUCaaSFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personel yerleştirme başarısız oldu."])},
    "sentFile": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("file")), "\" dosyası gönderilir."])},
    "sendFileError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["\"", _interpolate(_named("file")), "\" dosyası gönderilirken hata oluştu."])},
    "fileSizeExceeded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Seçilen dosya \"", _interpolate(_named("dosya")), "\" çok büyük."])},
    "receivedFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dosyası alındı:"])},
    "translationCapReached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çeviri işlevi şu anda kullanılamıyor."])}
  },
  "inputContainer": {
    "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönder"])},
    "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesaj girin..."])},
    "addEmoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emoji ekle"])},
    "sendFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dosya gönder"])}
  },
  "consentPage": {
    "consentingSentence": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Canli sohbet hizmetimizi kullanarak ", _interpolate(_list(0)), " okuduğunuzu ve kabul ettiginizi onaylamis olakcaksiniz."])},
    "startChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sohbet başlat"])}
  },
  "header": {
    "linkSection": {
      "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gizlilik politikası"])},
      "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["imprint"])}
    }
  },
  "error": {
    "initError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sohbet başlatılırken bir hata oluştu."])},
    "connectionError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bir bağlantı hatası oluştu. Bağlantıyı yeniden kurmak için bir girişimde bulunuluyor."])},
    "tryAgainButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekrar Dene"])},
    "initErrorHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bağlantı hatası"])},
    "connectionErrorHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bağlantı başarısız"])}
  }
}