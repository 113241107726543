
import MessageIn from '@/components/chat/MessageIn.vue';
import MessageOut from '@/components/chat/MessageOut.vue';
import { defineComponent, PropType } from 'vue';
import MessageSystem from '@/components/chat/MessageSystem.vue';
import LoadingSpinner from '@/components/main/LoadingSpinner.vue';
import { MessageSender } from '@/models/MessageSender';
import { ChatMessage } from '@/models/ChatMessage';
import { IncomingMessage } from '@/models/IncomingMessage';

export default defineComponent({
  components: {
    LoadingSpinner: LoadingSpinner,
    MessageSystem,
    MessageOut,
    MessageIn
  },
  props: {
    messageList: {
      type: Array as PropType<ChatMessage[]>,
      required: true
    },
    chatInitDone: Boolean,
    translator: String
  },
  created() {
    this.scrollToBottom();
  },
  watch: {
    messageList: {
      handler() {
        this.scrollToBottom();
      },
      deep: true
    }
  },
  methods: {
    async scrollToBottom() {
      await this.$nextTick();
      const chatDiv: HTMLElement | null = document.getElementById('chat');
      if (chatDiv != null) {
        chatDiv.scrollTo({
          top: chatDiv.scrollHeight,
          behavior: 'smooth'
        });
      }
    },
    isMessageOut(sender: MessageSender) {
      return sender === MessageSender.User;
    },
    isMessageIn(sender: MessageSender) {
      return sender === MessageSender.ChatBot || sender === MessageSender.UCaaS;
    },
    isMessageSystem(sender: MessageSender) {
      return sender === MessageSender.System;
    },
    onNewOutMessage(message: string) {
      this.$emit('newOutMessage', message);
    },
    onNewOutRecommendationMessage(parentMessage: IncomingMessage, recommendation: string) {
      this.$emit('newOutRecommendationMessage', parentMessage, recommendation);
    }
  }
});
