
import { defineComponent } from 'vue';
import SeparatorComponent from '@/components/chat/system/SeparatorComponent.vue';

export default defineComponent({
  components: { SeparatorComponent },
  props: {
    pageTitle: String,
    privacyPolicyUrl: String
  },
  data() {
    return {
      width: 0
    };
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  computed: {
    isMobile() {
      return this.width <= 576;
    }
  },
  methods: {
    onConsent() {
      this.$emit('consentedToPrivacyPolicy');
    },
    handleResize() {
      this.width = window.innerWidth;
    }
  }
});
