import * as DOMPurify from 'dompurify';

const URL_REGEX =
  /^(?!.+@)((?:http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b(:?[-a-zA-Z0-9@:%_+.~#?&//=]*))$/;
const EMAIL_REGEX = /^(\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+)$/;
const PHONE_NUMBER_REGEX = /((?:\+49|0049|0) ?\d{2,4}(?: ?|\/)\d+ ?\d*)/g;

export function highlightEmailsAndUrls(messageContent: string) {
  const sanitizedMessageContent = DOMPurify.sanitize(messageContent);
  return sanitizedMessageContent
    .split(/\s/)
    .map((value) => (URL_REGEX.test(value) ? handleUrls(value) : value))
    .map((value) => {
      if (EMAIL_REGEX.test(value)) {
        return `<a href="mailto:${value}" target="_blank" rel="noopener noreferrer">${value}</a>`;
      } else {
        return value;
      }
    })
    .join(' ')
    .replaceAll(PHONE_NUMBER_REGEX, '<a href="tel:$1" target="_blank" rel="noopener noreferrer">$1</a>'); //match most german phone numbers
}

function correctToAvoidInterpretationAsRelativeLink(url: string) {
  return url.startsWith('http') ? url : '//' + url;
}

function handleUrls(url: string) {
  if (!URL_REGEX.test(url)) return url;
  const correctedUrl = correctToAvoidInterpretationAsRelativeLink(url);
  return `<a href="${correctedUrl}" target="_blank" rel="noopener noreferrer">${url}</a>`;
}
