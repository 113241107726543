import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "file-type-icon" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isDocument)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-file" })
        ]))
      : (_ctx.isImage)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-image" })
          ]))
        : _createCommentVNode("", true)
  ]))
}