<template>
  <div class="encircled primary-color">
    <font-awesome-icon :icon="icon_type" style="height: 2em" />
  </div>
</template>

<script>
export default {
  name: 'EncircledIcon',
  props: {
    icon_type: String
  },
  computed: {
    icon() {
      return `fa-solid ${this.icon_type}`;
    }
  }
};
</script>

<style scoped>
.encircled {
  border: solid 4px;
  border-radius: 100%;
  height: 3em;
  width: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
