export default {
  "systemEvents": {
    "languageChange": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Język został zmieniony na: ", _interpolate(_named("language"))])},
    "switchedToUCaaS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zostałeś umieszczony z pracownikiem"])},
    "switchToUCaaSFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staż pracowniczy zakończył się niepowodzeniem."])},
    "sentFile": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["wysyłany jest plik \"", _interpolate(_named("file")), "\"."])},
    "sendFileError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Błąd przy wysyłaniu pliku \"", _interpolate(_named("file")), "\"."])},
    "fileSizeExceeded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wybrany plik \"", _interpolate(_named("file")), "\" jest zbyt duży."])},
    "receivedFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otrzymano plik:"])},
    "translationCapReached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funkcja tłumaczenia jest obecnie niedostępna."])}
  },
  "inputContainer": {
    "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij"])},
    "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wprowadź wiadomość ..."])},
    "addEmoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodaj emoji"])},
    "sendFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij plik"])}
  },
  "consentPage": {
    "consentingSentence": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Korzystając z czatu, potwierdzasz, że przeczytałeś i akceptujesz naszą ", _interpolate(_list(0)), "."])},
    "startChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozpocząć czat"])}
  },
  "header": {
    "linkSection": {
      "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["polityka prywatności"])},
      "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["imprint"])}
    }
  },
  "error": {
    "initError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podczas uruchamiania czatu wystąpił błąd."])},
    "connectionError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wystąpił błąd połączenia. Podejmowana jest próba ponownego nawiązania połączenia."])},
    "tryAgainButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spróbuj jeszcze raz"])},
    "initErrorHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd połączenia"])},
    "connectionErrorHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["connection failed"])}
  }
}