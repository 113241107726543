<template>
  <div class="spinner-wrapper">
    <div class="spinner-border primary-color m-5" role="status"></div>
  </div>
</template>
<style>
.spinner-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
