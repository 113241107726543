import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LanguageChooser = _resolveComponent("LanguageChooser")!
  const _component_LinkSection = _resolveComponent("LinkSection")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["top-bar d-flex justify-content-md-between justify-content-center align-content-center flex-wrap-reverse flex-md-nowrap background-primary gap-1", { 'rounded-top': _ctx.isTopRounded }])
  }, [
    _createVNode(_component_LanguageChooser, {
      onChangeLanguage: _ctx.onChangeLanguage,
      "language-changeable": _ctx.languageChangeable
    }, null, 8, ["onChangeLanguage", "language-changeable"]),
    _createVNode(_component_LinkSection, {
      privacyPolicyUrl: _ctx.privacyPolicyUrl,
      imprintUrl: _ctx.imprintUrl
    }, null, 8, ["privacyPolicyUrl", "imprintUrl"])
  ], 2))
}