import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00ac7952"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex justify-content-center flex-row px-2" }
const _hoisted_2 = {
  key: 0,
  class: "language-buttons"
}
const _hoisted_3 = ["value", "onClick"]
const _hoisted_4 = {
  key: 1,
  class: "language-buttons"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LanguageFlag = _resolveComponent("LanguageFlag")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.languageChangeable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$i18n.availableLocales, (locale) => {
            return (_openBlock(), _createElementBlock("a", {
              class: "language-button me-1 center-language-button",
              key: `locale-${locale}`,
              value: locale,
              onClick: ($event: any) => (_ctx.languageChangeable ? _ctx.onChangeLanguage(locale) : null)
            }, [
              _createVNode(_component_LanguageFlag, {
                "language-code": locale,
                "is-in-language-picker": true
              }, null, 8, ["language-code"])
            ], 8, _hoisted_3))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("a", null, [
            _createVNode(_component_LanguageFlag, {
              "language-code": _ctx.fallbackLanguage()
            }, null, 8, ["language-code"])
          ])
        ]))
  ]))
}