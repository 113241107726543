
import { defineComponent, PropType } from 'vue';
import LanguageFlag from '@/components/header/LanguageFlag.vue';
import FileAttachmentIcon from '@/components/chat/system/FileAttachmentIcon.vue';
import { SystemEvent } from '@/models/SystemEvent';
import { IncomingMessage } from '@/models/IncomingMessage';

export default defineComponent({
  components: { FileAttachmentIcon, LanguageFlag },
  props: {
    message: {
      type: Object as PropType<IncomingMessage>,
      required: true
    }
  },
  computed: {
    SystemEvent: () => SystemEvent,
    messageContentInTargetLanguage() {
      return this.message.translatedContent === this.message.content
        ? this.message.content
        : this.message.translatedContent;
    }
  }
});
