export default {
  "systemEvents": {
    "languageChange": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La langue a été changée en: ", _interpolate(_named("language"))])},
    "switchedToUCaaS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez été placé auprès d'un collaborateur."])},
    "switchToUCaaSFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le placement de collaborateurs a échoué."])},
    "sentFile": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["fichier \"", _interpolate(_named("file")), "\" envoyé."])},
    "sendFileError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Erreur lors de l'envoi du fichier \"", _interpolate(_named("file")), "\"."])},
    "fileSizeExceeded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le fichier \"", _interpolate(_named("file")), "\" sélectionné est trop volumineux."])},
    "receivedFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier reçu:"])},
    "translationCapReached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fonction de traduction n'est actuellement pas disponible."])}
  },
  "inputContainer": {
    "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
    "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisir le message..."])},
    "addEmoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un emoji"])},
    "sendFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer un fichier"])}
  },
  "consentPage": {
    "consentingSentence": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["En utilisant le chat, vous confirmez que vous avez lu notre ", _interpolate(_list(0)), " des données et que vous l’acceptez."])},
    "startChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démarrer le chat"])}
  },
  "header": {
    "linkSection": {
      "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de confidentialité"])},
      "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions légales"])}
    }
  },
  "error": {
    "initError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue au démarrage du chat"])},
    "connectionError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur de connexion s'est produite. Une tentative de rétablissement de la connexion est en cours."])},
    "tryAgainButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réessayer"])},
    "initErrorHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erreur de connexion"])},
    "connectionErrorHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echec de la connexion"])}
  }
}