
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    fileType: String
  },
  computed: {
    isDocument() {
      return this.fileType === 'application/pdf';
    },
    isImage() {
      if (!this.fileType) return false;
      return ['image/png', 'image/jpg', 'image/jpeg'].indexOf(this.fileType) > -1;
    }
  }
});
