import axios from 'axios';
import axiosRetry from 'axios-retry';

axiosRetry(axios, { retries: 3 });

//todo retrieve this one from backend / during build
export const fallbackLanguage = 'de';

export const changeLanguage = (lang: string, uuid: string, suppressResponse: boolean): void => {
  axios
    .post(`/api/user/${uuid}/language/${lang}`, suppressResponse, {
      headers: { 'Content-Type': 'application/json' }
    })
    .then((r) => r.data)
    .catch((r) => r.data);
};

export const languageInit = (lang: string, uuid: string): void => {
  axios
    .post(`/api/user/${uuid}/languageInit/${lang}`)
    .then((r) => r.data)
    .catch((r) => r.data);
};

export const getTranslateService = (): Promise<string> => {
  return axios.post(`/api/user/language/translate-service`).then((r) => r.data);
};
