import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-719f4220"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "language-flag m-1 center-flags"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  class: "language-flag m-1 center-flags"
}
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.isInLanguagePicker)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("img", {
          class: _normalizeClass(["language-flag-svg", [_ctx.$i18n.locale === $props.languageCode && 'language-flag-svg-active']]),
          src: require(`@/assets/img/flags/${$props.languageCode}.svg`)
        }, null, 10, _hoisted_2)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createElementVNode("img", {
          class: "language-flag-svg-no-pointer && language-flag-svg-active",
          src: require(`@/assets/img/flags/${$props.languageCode}.svg`)
        }, null, 8, _hoisted_4)
      ]))
}