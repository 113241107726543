import { Client, Message } from '@stomp/stompjs';
import axios from 'axios';
import axiosRetry, { isRetryableError } from 'axios-retry';
import { CommunicationStateChange } from '@/models/CommunicationStateChange';

axiosRetry(axios, {
  retries: 5,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error) => isRetryableError(error)
});

const generateWsUrl = () => {
  const wsProtocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
  return `${wsProtocol}//${window.location.host}/api/chatws`;
};

const stompClient = new Client({
  brokerURL: generateWsUrl(),
  heartbeatIncoming: 5000,
  heartbeatOutgoing: 5000,
  reconnectDelay: 10
});

export const createUser = async (
  username: string,
  handleInitError: (error: CommunicationStateChange) => void
): Promise<string> => {
  try {
    const res = await axios.post(
      '/api/createUser',
      { username: username },
      { headers: { 'Content-Type': 'application/json' } }
    );
    return res.data;
  } catch (e) {
    handleInitError(CommunicationStateChange.CHAT_INIT_ERROR);
    throw new Error('Error creating User.');
  }
};

const shouldInitInUCaaSMode = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.has('initMode') && urlParams.get('initMode')?.toUpperCase() === 'UCAAS';
};

export const initChat = async (uuid: string, handleCommunicationError: (error: CommunicationStateChange) => void) => {
  //TODO introduce logger
  try {
    const initEndpoint = shouldInitInUCaaSMode() ? 'initUCaaSChat' : `initChat`;
    await axios.post(`/api/${initEndpoint}/${uuid}`);
  } catch (e) {
    handleCommunicationError(CommunicationStateChange.CHAT_INIT_ERROR);
  }
};

export const wsInit = (
  uuid: string,
  handleReceivedMessage: (message: Message) => void,
  handleCommunicationStateChange: (state: CommunicationStateChange) => void
): void => {
  if (uuid === null) {
    handleCommunicationStateChange(CommunicationStateChange.CHAT_INIT_ERROR);
  }

  stompClient.connectHeaders = {
    user_id: uuid
  };
  stompClient.onConnect = () => onConnectSubscribeAndInit(uuid, handleReceivedMessage, handleCommunicationStateChange);
  stompClient.activate();

  stompClient.onStompError = () => handleCommunicationStateChange(CommunicationStateChange.CHAT_CONNECTION_ERROR);
  stompClient.onDisconnect = () => handleCommunicationStateChange(CommunicationStateChange.CHAT_CONNECTION_ERROR);
  stompClient.onWebSocketClose = () => handleCommunicationStateChange(CommunicationStateChange.CHAT_CONNECTION_ERROR);
  stompClient.onWebSocketError = () => handleCommunicationStateChange(CommunicationStateChange.CHAT_CONNECTION_ERROR);
};

export const closeWs = async () => {
  //TODO fix me atm underlying websocket is not closed properly, probably related https://github.com/stomp-js/stompjs/issues/196
  stompClient.forceDisconnect(); //https://stomp-js.github.io/api-docs/latest/classes/Client.html#forceDisconnect
  await stompClient.deactivate(); //https://stomp-js.github.io/api-docs/latest/classes/Client.html#deactivate
};

const onConnectSubscribeAndInit = async (
  uuid: string,
  handleReceivedMessage: (message: Message) => void,
  handleCommunicationStateChange: (state: CommunicationStateChange) => void
) => {
  const subscription = stompClient.subscribe(`/user/message/${uuid}`, handleReceivedMessage);
  const connectionSuccessful = subscription.id;
  if (connectionSuccessful) handleCommunicationStateChange(CommunicationStateChange.CHAT_CONNECTION_ESTABLISHED);
};

export const sendMessage = (uuid: string, message: string) => {
  stompClient.publish({
    destination: `/app/message/${uuid}`,
    body: message
  });
};
