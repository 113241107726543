<template>
  <div class="separator background-light-gray pt-1"></div>
</template>

<script>
export default {
  name: 'SeparatorComponent'
};
</script>

<style scoped></style>
