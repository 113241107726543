import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "message-wrapper-out me-0 ms-auto mt-2 mb-2" }
const _hoisted_2 = { class: "outgoing-message p-2" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "info-text time-out mt-1 mb-1 ms-2 me-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        innerHTML: _ctx.highlightEmailsAndUrls,
        class: "light-color p-2 light-text"
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.message.time.format('HH:mm:ss')), 1)
  ]))
}