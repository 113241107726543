export default {
  categoriesLabel: 'Catégories',
  emojiUnsupportedMessage: 'Votre navigateur ne soutient pas les emojis en couleur.',
  favoritesLabel: 'Favoris',
  loadingMessage: 'Chargement en cours…',
  networkErrorMessage: 'Impossible de charger les emojis.',
  regionLabel: 'Choisir un emoji',
  searchDescription:
    'Quand les résultats sont disponisbles, appuyez la fleche vers le haut ou le bas et la touche entrée pour choisir.',
  searchLabel: 'Rechercher',
  searchResultsLabel: 'Résultats',
  skinToneDescription: 'Quand disponible, appuyez la fleche vers le haut ou le bas et la touch entrée pour choisir.',
  skinToneLabel: 'Choisir une couleur de peau (actuellement {skinTone})',
  skinTonesLabel: 'Couleurs de peau',
  skinTones: ['Défaut', 'Clair', 'Moyennement clair', 'Moyen', 'Moyennement sombre', 'Sombre'],
  categories: {
    custom: 'Customisé',
    'smileys-emotion': 'Les smileyes et les émoticônes',
    'people-body': 'Les gens et le corps',
    'animals-nature': 'Les animaux et la nature',
    'food-drink': 'La nourriture et les boissons',
    'travel-places': 'Les voyages et les endroits',
    activities: 'Les activités',
    objects: 'Les objets',
    symbols: 'Les symbols',
    flags: 'Les drapeaux'
  }
};
