export default {
  categoriesLabel: 'Категорії',
  emojiUnsupportedMessage: 'Ваш браузер не підтримує кольорові емодзі.',
  favoritesLabel: 'Вибрані',
  loadingMessage: 'Завантаження…',
  networkErrorMessage: 'Не вдалося завантажити емодзі.',
  regionLabel: 'Вибір емодзі',
  searchDescription:
    'Коли результати пошуку будуть доступні, натисніть вгору або вниз, щоб вибрати, і Enter, щоб вибрати.',
  searchLabel: 'Пошук',
  searchResultsLabel: 'Результати пошуку',
  skinToneDescription: 'При розгортанні натисніть вгору або вниз, щоб вибрати, і Enter, щоб вибрати.',
  skinToneLabel: 'Виберіть колір шкіри (зараз {skinTone})',
  skinTonesLabel: 'Кольори шкіри',
  skinTones: ['За замовчуванням', 'Світлий', 'Середньо-світлий', 'Середній', 'Середньо-темний', 'Темний'],
  categories: {
    custom: 'Користувацькі',
    'smileys-emotion': 'Смайли і емодзі',
    'people-body': 'Люди і тіло',
    'animals-nature': 'Тварини і природа',
    'food-drink': 'Їжа і напої',
    'travel-places': 'Подорожі та місця',
    activities: 'Діяльність',
    objects: 'Обєкти',
    symbols: 'Символи',
    flags: 'Прапори'
  }
};
