import { createApp } from 'vue';
import App from './App.vue';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import { createI18n } from 'vue-i18n';

import de from './locales/de.json';
import pl from './locales/pl.json';
import en from './locales/en.json';
import es from './locales/es.json';
import fr from './locales/fr.json';
import ru from './locales/ru.json';
import uk from './locales/uk.json';
import tr from './locales/tr.json';
// import ar from './locales/ar.json';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faCheck,
  faFile,
  faImage,
  faPaperclip,
  faPaperPlane,
  faRedo,
  faTriangleExclamation,
  faWindowClose,
  faXmark,
  faExclamation,
  faRobot,
  faUser,
  faFaceLaugh,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { fallbackLanguage } from '@/services/LanguageService';

type MessageSchema = typeof de;
// const i18n = createI18n<[MessageSchema], 'de' | 'pl' | 'en' | 'es' | 'fr' | 'ru' | 'uk' | 'tr' | 'ar'>({
const i18n = createI18n<[MessageSchema], 'de' | 'pl' | 'en' | 'es' | 'fr' | 'ru' | 'uk' | 'tr'>({
  legacy: false,
  locale: navigator.language.split('-')[0],
  fallbackLocale: fallbackLanguage,
  messages: {
    de: de,
    pl: pl,
    en: en,
    es: es,
    fr: fr,
    ru: ru,
    uk: uk,
    tr: tr
    // ar: ar
  }
});

library.add(
  faCircleXmark,
  faCheck,
  faPaperPlane,
  faPaperclip,
  faFile,
  faImage,
  faWindowClose,
  faTriangleExclamation,
  faRedo,
  faXmark,
  faExclamation,
  faRobot,
  faUser,
  faFaceLaugh,
  faPlus
);

const app = createApp(App);
app.use(i18n);
app.component('font-awesome-icon', FontAwesomeIcon);
app.component('emoji-picker');
//TODO LTR / RTL for arabic language
app.mount('#app');
