<template>
  <div class="emoji-picker-window">
    <emoji-picker></emoji-picker>
  </div>
</template>

<script>
import 'emoji-picker-element';
import fr from '@/locales/emoji-i18n/fr';
import de from '@/locales/emoji-i18n/de';
import en from '@/locales/emoji-i18n/en';
import es from '@/locales/emoji-i18n/es';
import pl from '@/locales/emoji-i18n/pl';
import ru from '@/locales/emoji-i18n/ru_RU';
import tr from '@/locales/emoji-i18n/tr';
import uk from '@/locales/emoji-i18n/uk';

const i18nMap = {
  de: de,
  fr: fr,
  en: en,
  es: es,
  pl: pl,
  ru: ru,
  tr: tr,
  uk: uk
};
export default {
  props: {
    lang: String
  },
  mounted() {
    this.picker = document.querySelector('emoji-picker');
    this.picker.addEventListener('emoji-click', (event) => this.onClickEmojiPicker(event.detail));
    this.picker.i18n = i18nMap[this.lang];
  },
  watch: {
    lang: function (newLang) {
      this.picker.i18n = i18nMap[newLang];
    }
  },
  methods: {
    onClickEmojiPicker(onEmojiClickEvent) {
      this.$emit('ClickEmojiPicker', onEmojiClickEvent);
    }
  }
};
</script>

<style scoped>
emoji-picker {
  width: 100%;
  height: 100%;
  --num-columns: 5;
  --category-emoji-size: 0.75rem;
}

.emoji-picker-window {
  width: 250px;
  height: 400px;
  position: absolute;
  bottom: 120px;
  left: 10px;
  z-index: 1000;
}
</style>
