import axios from 'axios';

export const uploadFile = async (userUuid: string, file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  return axios.post(`/api/file/${userUuid}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
