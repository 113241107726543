export default {
  "systemEvents": {
    "languageChange": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El lenguaje fue cambiado a: ", _interpolate(_named("language"))])},
    "switchedToUCaaS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te han colocado con un miembro del personal."])},
    "switchToUCaaSFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La colocación del personal ha fracasado."])},
    "sentFile": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El archivo \"", _interpolate(_named("file")), "\" se envía."])},
    "sendFileError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Error al enviar el archivo \"", _interpolate(_named("file")), "\"."])},
    "fileSizeExceeded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["El archivo seleccionado \"", _interpolate(_named("archivo")), "\" es demasiado grande."])},
    "receivedFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivo recibido:"])},
    "translationCapReached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La función de traducción no está disponible actualmente."])}
  },
  "inputContainer": {
    "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
    "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisir le message..."])},
    "addEmoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar emoji"])},
    "sendFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar archivo"])}
  },
  "consentPage": {
    "consentingSentence": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Con el uso del chat confirma que ha leído y aceptado nuestra ", _interpolate(_list(0)), "."])},
    "startChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar el chat"])}
  },
  "header": {
    "linkSection": {
      "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de confidentialité"])},
      "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions légales"])}
    }
  },
  "error": {
    "initError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error al iniciar el chat."])},
    "connectionError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error de conexión. Se está intentando restablecer la conexión."])},
    "tryAgainButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inténtalo de nuevo"])},
    "initErrorHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error de conexión"])},
    "connectionErrorHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conexión fallida"])}
  }
}