export default {
  "systemEvents": {
    "languageChange": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Мова була змінена на: ", _interpolate(_named("language"))])},
    "switchedToUCaaS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вас помістили до співробітника."])},
    "switchToUCaaSFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розстановка кадрів провалена."])},
    "sentFile": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["відправляється файл \"", _interpolate(_named("file")), "\"."])},
    "sendFileError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Помилка відправки файлу \"", _interpolate(_named("file")), "\"."])},
    "fileSizeExceeded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Вибраний файл \"", _interpolate(_named("file")), "\" має занадто великий розмір."])},
    "receivedFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл отримано:"])},
    "translationCapReached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Функція перекладу наразі недоступна."])}
  },
  "inputContainer": {
    "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відправити"])},
    "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть повідомлення..."])},
    "addEmoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати емодзі"])},
    "sendFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Надіслати файл"])}
  },
  "consentPage": {
    "consentingSentence": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Використовуючи чат, ви підтверджуєте, що приймаєте наш ", _interpolate(_list(0)), " прочитали та прийміть їх."])},
    "startChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почати чат"])}
  },
  "header": {
    "linkSection": {
      "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["політика конфіденційності"])},
      "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відбиток"])}
    }
  },
  "error": {
    "initError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Під час запуску чату виникла помилка."])},
    "connectionError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виникла помилка підключення. Робиться спроба відновити зв'язок"])},
    "tryAgainButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спробуйте ще раз"])},
    "initErrorHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка підключення"])},
    "connectionErrorHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["з'єднання не вдалося"])}
  }
}