export default {
  "systemEvents": {
    "languageChange": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Формулировка была изменена следующим образом: ", _interpolate(_named("language"))])},
    "switchedToUCaaS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вас поместили к одному из сотрудников."])},
    "switchToUCaaSFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расстановка персонала не удалась."])},
    "sentFile": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["отправлен файл \"", _interpolate(_named("file")), "\"."])},
    "sendFileError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ошибка при отправке файла \"", _interpolate(_named("file")), "\"."])},
    "fileSizeExceeded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Выбранный файл \"", _interpolate(_named("file")), "\" слишком большой."])},
    "receivedFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получен файл:"])},
    "translationCapReached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Функция перевода в настоящее время недоступна."])}
  },
  "inputContainer": {
    "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])},
    "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите сообщение..."])},
    "addEmoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить эмодзи"])},
    "sendFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить файл"])}
  },
  "consentPage": {
    "consentingSentence": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["При использовании сайта вы подтверждаете,что прочитали и акцептировали нашу ", _interpolate(_list(0)), "."])},
    "startChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начать общение"])}
  },
  "header": {
    "linkSection": {
      "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["политика конфиденциальности"])},
      "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["отпечаток"])}
    }
  },
  "error": {
    "initError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка при запуске чата."])},
    "connectionError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка соединения. Предпринимается попытка восстановить соединение"])},
    "tryAgainButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попробуйте еще раз"])},
    "initErrorHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка соединения"])},
    "connectionErrorHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["соединение не удалось"])}
  }
}