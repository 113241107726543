import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center justify-content-center flex-row px-0 link-section px-2 me-1" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      href: $props.privacyPolicyUrl,
      target: "_blank",
      class: "px-2 light-link"
    }, _toDisplayString(_ctx.$t('header.linkSection.privacyPolicy')), 9, _hoisted_2),
    _createElementVNode("a", {
      href: $props.imprintUrl,
      target: "_blank",
      class: "light-link"
    }, _toDisplayString(_ctx.$t('header.linkSection.imprint')), 9, _hoisted_3)
  ]))
}