export enum SystemEvent {
  LANGUAGE_CHANGED = 'LANGUAGE_CHANGED',
  SWITCHED_TO_UCAAS = 'SWITCHED_TO_UCAAS',
  SWITCH_TO_UCAAS_FAILED = 'SWITCH_TO_UCAAS_FAILED',
  FILE_SENT = 'FILE_SENT',
  FILE_SEND_ERROR = 'FILE_SEND_ERROR',
  FILE_SIZE_EXCEEDED = 'FILE_SIZE_EXCEEDED',
  MESSAGE_ATTACHMENT = 'MESSAGE_ATTACHMENT', //aka FILE_RECEIVED on this side
  TRANSLATION_API_CAP_REACHED = 'TRANSLATION_API_CAP_REACHED'
}
