import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "container border border-light bg-white rounded-bottom rounded-top h-100 error-message" }
const _hoisted_2 = {
  class: "my-2",
  style: {"text-align":"center"}
}
const _hoisted_3 = { class: "my-1" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "text" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "text error-text" }
const _hoisted_8 = {
  type: "button",
  class: "btn primary-color-button btn-lg my-2",
  onclick: "location.reload();"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EncircledIcon = _resolveComponent("EncircledIcon")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_EncircledIcon, {
      icon_type: "fa-exclamation",
      class: "my-5"
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('error.initErrorHeading').toUpperCase()), 1),
      (_ctx.isInitError)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('error.initError')), 1)
          ]))
        : (_ctx.isConnectionError)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('error.connectionError')), 1),
              _createVNode(_component_LoadingSpinner)
            ]))
          : _createCommentVNode("", true)
    ]),
    _createElementVNode("button", _hoisted_8, [
      _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-redo" }),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('error.tryAgainButton')), 1)
    ])
  ]))
}