
import LanguageChooser from '../header/LanguageChooser.vue';
import LinkSection from '../header/LinkSection.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    LanguageChooser,
    LinkSection
  },
  props: {
    languageChangeable: Boolean,
    privacyPolicyUrl: String,
    imprintUrl: String
  },
  data() {
    return {
      width: 0
    };
  },
  computed: {
    isTopRounded() {
      return this.width > 576;
    }
  },
  methods: {
    onChangeLanguage(lang: string) {
      this.$emit('changeLanguage', lang);
    },
    handleResize() {
      this.width = window.innerWidth;
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }
});
