export default {
  "systemEvents": {
    "languageChange": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die Sprache wurde geändert zu: ", _interpolate(_named("language"))])},
    "switchedToUCaaS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie wurden zu einem Mitarbeiter vermittelt."])},
    "switchToUCaaSFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Mitarbeitervermittlung ist fehlgeschlagen."])},
    "sentFile": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Datei \"", _interpolate(_named("file")), "\" gesendet."])},
    "sendFileError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fehler beim senden der Datei \"", _interpolate(_named("file")), "\"."])},
    "fileSizeExceeded": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die gewählte Datei \"", _interpolate(_named("file")), "\" ist zu groß."])},
    "receivedFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei empfangen:"])},
    "translationCapReached": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Übersetzungsfunktion ist aktuell nicht verfügbar."])}
  },
  "inputContainer": {
    "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden"])},
    "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht eingeben..."])},
    "addEmoji": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emoji hinzufügen"])},
    "sendFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei senden"])}
  },
  "consentPage": {
    "consentingSentence": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Mit der Nutzung des Chats bestätigen Sie, dass Sie unsere ", _interpolate(_list(0)), " gelesen haben und diese akzeptieren."])},
    "startChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat starten"])}
  },
  "header": {
    "linkSection": {
      "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzerklärung"])},
      "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])}
    }
  },
  "error": {
    "initError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Start des Chats ist ein Fehler aufgetreten."])},
    "connectionError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist ein Verbindungsfehler aufgetreten. Es wird versucht die Verbindung wiederherzustellen."])},
    "tryAgainButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneut versuchen"])},
    "initErrorHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindungsfehler"])},
    "connectionErrorHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindung fehlgeschlagen"])}
  }
}