
import HeaderContainer from './main/HeaderContainer.vue';
import InputContainer from './main/InputContainer.vue';
import ChatContainer from './main/ChatContainer.vue';
import * as chatService from '@/services/ChatService';
import { closeWs, initChat } from '@/services/ChatService';
import * as languageService from '@/services/LanguageService';
import { fallbackLanguage } from '@/services/LanguageService';
import moment from 'moment';
import { defineComponent } from 'vue';
import { uploadFile } from '@/services/FileService';
import ErrorComponent from '@/components/ErrorComponent.vue';
import ConsentComponent from '@/components/ConsentComponent.vue';
import { IMessage, Message } from '@stomp/stompjs';
import { CommunicationStateChange } from '@/models/CommunicationStateChange';
import { MessageSender } from '@/models/MessageSender';
import { SystemEvent } from '@/models/SystemEvent';
import { ChatMessage } from '@/models/ChatMessage';
import { IncomingMessage } from '@/models/IncomingMessage';

const __default__ = defineComponent({
  components: {
    ChatContainer,
    HeaderContainer,
    InputContainer,
    ErrorComponent,
    ConsentComponent
  },
  data() {
    return {
      languageChangeable: true as boolean,
      initError: false as boolean,
      connectionError: false as boolean,
      connectionEstablished: false as boolean,
      hasGivenConsentToPrivacyPolicy: false as boolean,
      privacyPolicyUrl: 'https://www.ehlert-gmbh.de/wp-content/uploads/2023/02/FB05-01-Datenschutzerklaerung.pdf', //todo make these dynamic as well
      imprintUrl: 'https://www.ehlert-shop.de/impressum-cms-systempage.helpdesk.impressum', //todo make these dynamic as well
      inUCaaSMode: false as boolean,
      userUuid: undefined as string | undefined,
      username: 'supportUser' as string,
      messageList: [] as ChatMessage[],
      fileSends: false as boolean,
      showEmojiPicker: false as boolean,
      showInputActions: false as boolean,
      shadowEffect: false as boolean,
      lang: '' as string,
      translator: '' as string,
      corporateIdentity: {
        pageTitle: 'EHLERT SERVICE',
        primaryColor: '#b90617',
        lightColor: '#fcfcfc',
        lightGrayColor: '#eef2f0',
        secondaryColor: '#44484c',
        h1Size: '30px',
        textSize: '16px',
        linkSize: '12px',
        fonts: {
          accent: {
            link: '/fonts/BreeSerif.ttf',
            name: 'Bree Serif',
            weight: 400
          },
          text: {
            link: '/fonts/Montserrat-Regular.ttf',
            name: 'Montserrat',
            weight: 600
          }
        }
      }
    };
  },
  beforeMount() {
    this.setDocumentTitle();
  },
  mounted: async function () {
    await this.loadFont(this.corporateIdentity.fonts.accent.name, this.corporateIdentity.fonts.accent.link);
    await this.loadFont(this.corporateIdentity.fonts.text.name, this.corporateIdentity.fonts.text.link);

    this.userUuid = await chatService.createUser(this.username, this.handleCommunicationStateChange);

    //TODO: refactor + add error handling for unsupported languages
    languageService.languageInit(this.getNavigatorLanguageCode(), this.userUuid);
    this.translator = await languageService.getTranslateService();

    chatService.wsInit(this.userUuid, this.handleReceivedMessage, this.handleCommunicationStateChange);
  },
  methods: {
    setDocumentTitle: function () {
      document.title = this.corporateIdentity.pageTitle || 'klose brothers GmbH Chatbot';
    },
    loadFont: async function (fontFamily: string, fontLink: string) {
      const font = new FontFace(fontFamily, `url(${fontLink})`);
      await font.load();
      document.fonts.add(font);
    },
    handleCommunicationStateChange: async function (error: CommunicationStateChange) {
      switch (error) {
        case CommunicationStateChange.CHAT_CONNECTION_ERROR:
          this.connectionError = true;
          break;
        case CommunicationStateChange.CHAT_INIT_ERROR:
          await closeWs();
          this.initError = true;
          break;
        case CommunicationStateChange.CHAT_CONNECTION_ESTABLISHED:
          this.connectionError = false;
          this.connectionEstablished = true;
          break;
      }
    },
    parseRawMessage: function (message: IMessage): IncomingMessage {
      const parsedMessage: IncomingMessage = JSON.parse(message.body);
      parsedMessage.time = moment();
      parsedMessage.recommendationClicked = false;
      return parsedMessage;
    },
    handleReceivedMessage: function (message: Message) {
      const parsedMessage: IncomingMessage = this.parseRawMessage(message);

      if (parsedMessage.systemEvent) this.handleSystemMessages(parsedMessage);
      this.messageList.push(parsedMessage);
    },
    handleSystemMessages: async function (parsedMessage: IncomingMessage) {
      switch (parsedMessage.systemEvent) {
        case SystemEvent.LANGUAGE_CHANGED: {
          this.handleLanguageChangeMessage(parsedMessage);
          break;
        }
        case SystemEvent.SWITCHED_TO_UCAAS: {
          parsedMessage.content = this.$t('systemEvents.switchedToUCaaS');
          this.inUCaaSMode = true;
          break;
        }
        case SystemEvent.SWITCH_TO_UCAAS_FAILED: {
          parsedMessage.content = this.$t('systemEvents.switchToUCaaSFailed');
          break;
        }
        case SystemEvent.MESSAGE_ATTACHMENT: {
          const eventContent = JSON.parse(parsedMessage.content);
          parsedMessage.systemEventContent = eventContent;
          parsedMessage.content = this.$t('systemEvents.receivedFile', { file: eventContent.fileName });
          break;
        }
        case SystemEvent.TRANSLATION_API_CAP_REACHED: {
          parsedMessage.content = this.$t('systemEvents.translationCapReached');
          await this.onChangeLanguage(fallbackLanguage);
          this.languageChangeable = false;

          break;
        }
      }
    },
    getNavigatorLanguageCode: function () {
      this.lang = navigator.language.split('-')[0];
      return this.lang;
    },
    handleLanguageChangeMessage: function (parsedMessage: IncomingMessage) {
      parsedMessage.content = this.$t('systemEvents.languageChange', { language: '' });
      parsedMessage.systemEventContent = {
        targetLocale: this.$i18n.locale
      };
    },
    onConsent: function () {
      this.hasGivenConsentToPrivacyPolicy = true;
      if (!this.userUuid) return;
      initChat(this.userUuid, this.handleCommunicationStateChange);
    },
    onNewOutMessage: function (message: string) {
      if (!message || !this.userUuid) return;
      const sentMessage: ChatMessage = {
        sender: MessageSender.User,
        content: message,
        time: moment()
      };
      this.messageList.push(sentMessage);
      this.disableAllRecommendations();
      chatService.sendMessage(this.userUuid, message);
    },
    onNewOutRecommendationMessage: function (message: IncomingMessage, recommendation: string) {
      message.recommendationClicked = true; //TODO refactor this since passing the msg up by reference is not necessary as it seems and just bad style
      this.onNewOutMessage(recommendation);
    },
    disableAllRecommendations: function () {
      this.messageList
        .filter((message) => Object.prototype.hasOwnProperty.call(message, 'recommendationClicked'))
        .forEach((message) => ((message as IncomingMessage).recommendationClicked = true));
    },
    createSendFileErrorMessage: function (file: File) {
      return {
        sender: MessageSender.System,
        content: this.$t('systemEvents.sentFile', { file: file.name }),
        translatedContent: '',
        time: moment(),
        systemEvent: SystemEvent.FILE_SENT,
        systemEventContent: { file: file }
      };
    },
    createSentFileMessage: function (file: File) {
      return {
        sender: MessageSender.System,
        content: this.$t('systemEvents.sendFileError', { file: file.name }),
        translatedContent: '',
        time: moment(),
        systemEvent: SystemEvent.FILE_SEND_ERROR,
        systemEventContent: { file: file }
      };
    },
    createFileSizeExceededMsg: function (file: File) {
      return {
        sender: MessageSender.System,
        content: this.$t('systemEvents.fileSizeExceeded', { file: file.name }),
        translatedContent: '',
        time: moment(),
        systemEvent: SystemEvent.FILE_SIZE_EXCEEDED,
        systemEventContent: { file: file }
      };
    },
    async onNewFileUpload(file: File) {
      if (!this.inUCaaSMode || !file || !this.userUuid) return;
      if (file.size > 100000000) {
        const fileSizeExceededMsg: ChatMessage = this.createFileSizeExceededMsg(file);
        this.messageList.push(fileSizeExceededMsg);
        return;
      }
      try {
        this.fileSends = true;
        await uploadFile(this.userUuid, file);
        this.fileSends = false;

        const fileSentMessage: ChatMessage = this.createSendFileErrorMessage(file);
        this.messageList.push(fileSentMessage);
      } catch (error) {
        const fileSendErrorMessage: ChatMessage = this.createSentFileMessage(file);
        this.messageList.push(fileSendErrorMessage);
        this.fileSends = false;
      }
    },
    async onChangeLanguage(lang: string) {
      if (this.$i18n.locale === lang || !this.userUuid) return;
      this.$i18n.locale = lang;
      this.lang = lang;
      languageService.changeLanguage(lang, this.userUuid, this.shouldSuppressResponseForLanguageChange);
    },
    onClickChatContainer() {
      this.showInputActions = false;
      this.shadowEffect = false;
      this.showEmojiPicker = false;
    },
    onShowEmojiPicker() {
      this.showEmojiPicker = true;
    },
    onHideEmojiPicker() {
      this.showEmojiPicker = false;
    },
    onShowInputActions() {
      this.showInputActions = true;
    },
    onHideInputActions() {
      this.showInputActions = false;
    },
    onShowShadow() {
      this.shadowEffect = true;
    },
    onHideShadow() {
      this.shadowEffect = false;
    }
  },
  computed: {
    shouldSuppressResponseForLanguageChange(): boolean {
      return Boolean(!this.hasGivenConsentToPrivacyPolicy);
    },
    isInitError(): boolean {
      return Boolean(this.initError);
    },
    isConnectionError(): boolean {
      return Boolean(this.connectionError);
    },
    fileIsSending(): boolean {
      return Boolean(this.fileSends);
    },
    isInUCaaSMode(): boolean {
      return Boolean(this.inUCaaSMode);
    },
    initMessageFromBotPresent(): boolean {
      return Boolean(this.messageList.length);
    },
    inputDisabled(): boolean {
      return !this.initMessageFromBotPresent;
    },
    chatInitDone(): boolean {
      return this.initMessageFromBotPresent;
    },
    readyToInitChat(): boolean {
      return Boolean(this.userUuid && this.hasGivenConsentToPrivacyPolicy && this.connectionEstablished);
    }
  },
  watch: {
    userUuid: function (value: string) {
      //TODO validate if this could be improved -> when user clicks on "consent" before init with backend is done
      if (!this.readyToInitChat) return;
      initChat(value, this.handleCommunicationStateChange);
    },
    connectionEstablished: function () {
      //TODO validate if this could be improved -> when user clicks on "consent" before init with backend is done
      if (!this.readyToInitChat) return;
      if (!this.userUuid) return; //TS compiler is dumb, above line does that already
      initChat(this.userUuid, this.handleCommunicationStateChange);
    }
  }
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "dca79e36": (_ctx.corporateIdentity.primaryColor),
  "a02dc89a": (_ctx.corporateIdentity.secondaryColor),
  "1aff9f51": (_ctx.corporateIdentity.lightColor),
  "3df00464": (_ctx.corporateIdentity.h1Size),
  "c802c99c": (_ctx.corporateIdentity.textSize),
  "1efed77f": (_ctx.corporateIdentity.linkSize),
  "2c9be65e": (_ctx.corporateIdentity.fonts.text.name),
  "f663f4ea": (_ctx.corporateIdentity.fonts.text.weight),
  "fe42e73e": (_ctx.corporateIdentity.fonts.accent.name),
  "5a019264": (_ctx.corporateIdentity.fonts.accent.weight),
  "cbe6f8a4": (_ctx.corporateIdentity.lightGrayColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__