export default {
  categoriesLabel: 'Kategorie',
  emojiUnsupportedMessage: 'Twoja przeglądarka nie obsługuje kolorowych emoji.',
  favoritesLabel: 'Ulubione',
  loadingMessage: 'Ładowanie…',
  networkErrorMessage: 'Nie można załadować emoji.',
  regionLabel: 'Wybieranie emoji',
  searchDescription:
    'Po uzyskaniu wyników wyszukiwania naciśnij górę lub dół, aby wybrać, a następnie Enter, aby wybrać.',
  searchLabel: 'Szukaj',
  searchResultsLabel: 'Wyniki wyszukiwania',
  skinToneDescription: 'Po rozszerzeniu naciśnij górę lub dół, aby wybrać, a następnie Enter, aby wybrać.',
  skinToneLabel: 'Wybierz odcień skóry (obecnie {skinTone})',
  skinTonesLabel: 'Odcienie skóry',
  skinTones: ['Domyślny', 'Jasny', 'Średnio-jasny', 'Średni', 'Średnio-ciemny', 'Ciemny'],
  categories: {
    custom: 'Niestandardowe',
    'smileys-emotion': 'Uśmiechy i emotikony',
    'people-body': 'Ludzie i ciało',
    'animals-nature': 'Zwierzęta i natura',
    'food-drink': 'Jedzenie i picie',
    'travel-places': 'Podróże i miejsca',
    activities: 'Aktywności',
    objects: 'Obiekty',
    symbols: 'Symbole',
    flags: 'Flagi'
  }
};
