
import { defineComponent, PropType } from 'vue';
import { ChatMessage } from '@/models/ChatMessage';
import { highlightEmailsAndUrls } from '@/components/chat/utils';

export default defineComponent({
  props: {
    message: {
      type: Object as PropType<ChatMessage>,
      required: true
    }
  },
  computed: {
    highlightEmailsAndUrls() {
      return highlightEmailsAndUrls(this.message.content);
    }
  }
});
