import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e3b33ea6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "container border border-light bg-white kb-hexagon-background kb-hexagon-background-chat",
  id: "chat"
}
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MessageOut = _resolveComponent("MessageOut")!
  const _component_MessageIn = _resolveComponent("MessageIn")!
  const _component_MessageSystem = _resolveComponent("MessageSystem")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.chatInitDone)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messageList, (message) => {
            return (_openBlock(), _createElementBlock("div", {
              key: message.time
            }, [
              (_ctx.isMessageOut(message.sender))
                ? (_openBlock(), _createBlock(_component_MessageOut, {
                    key: 0,
                    message: message
                  }, null, 8, ["message"]))
                : (_ctx.isMessageIn(message.sender))
                  ? (_openBlock(), _createBlock(_component_MessageIn, {
                      key: 1,
                      message: message,
                      translator: _ctx.translator,
                      onNewOutMessage: _ctx.onNewOutMessage,
                      onNewOutRecommendationMessage: _ctx.onNewOutRecommendationMessage
                    }, null, 8, ["message", "translator", "onNewOutMessage", "onNewOutRecommendationMessage"]))
                  : (_ctx.isMessageSystem(message.sender))
                    ? (_openBlock(), _createBlock(_component_MessageSystem, {
                        key: 2,
                        message: message
                      }, null, 8, ["message"]))
                    : _createCommentVNode("", true)
            ]))
          }), 128))
        ]))
      : (_openBlock(), _createBlock(_component_LoadingSpinner, { key: 1 }))
  ]))
}